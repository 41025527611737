import { default as _91_46_46_46catchAll_93W1mtiUGrw0Meta } from "/codebuild/output/src2980918246/src/storefront/pages/[...catchAll].vue?macro=true";
import { default as about_45usmC7xlVlv8qMeta } from "/codebuild/output/src2980918246/src/storefront/pages/about-us.vue?macro=true";
import { default as barion_45startTT3MdPJpSvMeta } from "/codebuild/output/src2980918246/src/storefront/pages/barion-start.vue?macro=true";
import { default as bestsellers_45of_45the_45monthji3dzUvC3xMeta } from "/codebuild/output/src2980918246/src/storefront/pages/bestsellers-of-the-month.vue?macro=true";
import { default as book_45clubiOjVEq6C16Meta } from "/codebuild/output/src2980918246/src/storefront/pages/book-club.vue?macro=true";
import { default as _91slug_93Ccm9vjpWTFMeta } from "/codebuild/output/src2980918246/src/storefront/pages/book/[slug].vue?macro=true";
import { default as _91slug_93xlqKE8tV0IMeta } from "/codebuild/output/src2980918246/src/storefront/pages/books-by-author/[slug].vue?macro=true";
import { default as indexwJSzQlQEpAMeta } from "/codebuild/output/src2980918246/src/storefront/pages/books/[category]/[subcategory]/index.vue?macro=true";
import { default as indexqYwgIXXFjZMeta } from "/codebuild/output/src2980918246/src/storefront/pages/books/[category]/index.vue?macro=true";
import { default as indexXCRVd88LcNMeta } from "/codebuild/output/src2980918246/src/storefront/pages/books/index.vue?macro=true";
import { default as indexyv0kPvPNYWMeta } from "/codebuild/output/src2980918246/src/storefront/pages/checkout/index.vue?macro=true";
import { default as thankyouVavZgIeagmMeta } from "/codebuild/output/src2980918246/src/storefront/pages/checkout/thankyou.vue?macro=true";
import { default as contact_45us2MY0xvXpwfMeta } from "/codebuild/output/src2980918246/src/storefront/pages/contact-us.vue?macro=true";
import { default as cookieTY0bfmzeKKMeta } from "/codebuild/output/src2980918246/src/storefront/pages/cookie.vue?macro=true";
import { default as indexTSf5ni5fYIMeta } from "/codebuild/output/src2980918246/src/storefront/pages/index.vue?macro=true";
import { default as loginnrxJd6WyPXMeta } from "/codebuild/output/src2980918246/src/storefront/pages/login.vue?macro=true";
import { default as _91slug_93JF0ivdzjKLMeta } from "/codebuild/output/src2980918246/src/storefront/pages/menu-navigation/[slug].vue?macro=true";
import { default as my_45cartutMbe2ggIcMeta } from "/codebuild/output/src2980918246/src/storefront/pages/my-cart.vue?macro=true";
import { default as _91slug_93kLLMoWotygMeta } from "/codebuild/output/src2980918246/src/storefront/pages/my-orders/[slug].vue?macro=true";
import { default as indexsXJWS9OUR9Meta } from "/codebuild/output/src2980918246/src/storefront/pages/my-orders/index.vue?macro=true";
import { default as newly_45published3Bkz0k3a2EMeta } from "/codebuild/output/src2980918246/src/storefront/pages/newly-published.vue?macro=true";
import { default as newspapersgbrctaWYP2Meta } from "/codebuild/output/src2980918246/src/storefront/pages/newspapers.vue?macro=true";
import { default as on_45saleZyWlZ2zV66Meta } from "/codebuild/output/src2980918246/src/storefront/pages/on-sale.vue?macro=true";
import { default as _91slug_93nCjec526QTMeta } from "/codebuild/output/src2980918246/src/storefront/pages/other-language/[slug].vue?macro=true";
import { default as indexuo8iPhqjbRMeta } from "/codebuild/output/src2980918246/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue?macro=true";
import { default as index80CJ1ibi2UMeta } from "/codebuild/output/src2980918246/src/storefront/pages/other-languages/[category]/index.vue?macro=true";
import { default as indexHn33N1pXDsMeta } from "/codebuild/output/src2980918246/src/storefront/pages/other-languages/index.vue?macro=true";
import { default as payment_45resultyL6DE5YJScMeta } from "/codebuild/output/src2980918246/src/storefront/pages/payment-result.vue?macro=true";
import { default as paypal_45startEo1FzmM0blMeta } from "/codebuild/output/src2980918246/src/storefront/pages/paypal-start.vue?macro=true";
import { default as privacy_45policyXWNXGHuVsAMeta } from "/codebuild/output/src2980918246/src/storefront/pages/privacy-policy.vue?macro=true";
import { default as _91_91subcategory_93_93KWZANLsm5OMeta } from "/codebuild/output/src2980918246/src/storefront/pages/products/[[category]]/[[subcategory]].vue?macro=true";
import { default as purchase_45and_45shipping_45conditionspzAwhrdlCrMeta } from "/codebuild/output/src2980918246/src/storefront/pages/purchase-and-shipping-conditions.vue?macro=true";
import { default as searchJwTi70F8GzMeta } from "/codebuild/output/src2980918246/src/storefront/pages/search.vue?macro=true";
import { default as staff_45recommendationsKhPeqgvjA4Meta } from "/codebuild/output/src2980918246/src/storefront/pages/staff-recommendations.vue?macro=true";
import { default as _91slug_93xID3gtxDuvMeta } from "/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gift/[slug].vue?macro=true";
import { default as indexvkbzGkNvijMeta } from "/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue?macro=true";
import { default as index09q5p1SU8IMeta } from "/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gifts/[category]/index.vue?macro=true";
import { default as indexygIyY15OqJMeta } from "/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gifts/index.vue?macro=true";
import { default as terms_45and_45conditionsnvwezunWLZMeta } from "/codebuild/output/src2980918246/src/storefront/pages/terms-and-conditions.vue?macro=true";
import { default as upcoming_45releasesThQIBxlGMWMeta } from "/codebuild/output/src2980918246/src/storefront/pages/upcoming-releases.vue?macro=true";
export default [
  {
    name: "catchAll___hu",
    path: "/hu/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93W1mtiUGrw0Meta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "catchAll___en",
    path: "/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93W1mtiUGrw0Meta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "about-us___hu",
    path: "/hu/rolunk",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/about-us.vue")
  },
  {
    name: "about-us___en",
    path: "/about-us",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/about-us.vue")
  },
  {
    name: "barion-start___hu",
    path: "/hu/barion-fizetes",
    meta: barion_45startTT3MdPJpSvMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/barion-start.vue")
  },
  {
    name: "barion-start___en",
    path: "/barion-start",
    meta: barion_45startTT3MdPJpSvMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/barion-start.vue")
  },
  {
    name: "bestsellers-of-the-month___hu",
    path: "/hu/a-honap-sikerkonyvei",
    meta: bestsellers_45of_45the_45monthji3dzUvC3xMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "bestsellers-of-the-month___en",
    path: "/bestsellers-of-the-month",
    meta: bestsellers_45of_45the_45monthji3dzUvC3xMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "book-club___hu",
    path: "/hu/konyvklub",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/book-club.vue")
  },
  {
    name: "book-club___en",
    path: "/book-club",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/book-club.vue")
  },
  {
    name: "book-slug___hu",
    path: "/hu/konyv/:slug()",
    meta: _91slug_93Ccm9vjpWTFMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "book-slug___en",
    path: "/book/:slug()",
    meta: _91slug_93Ccm9vjpWTFMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "books-by-author-slug___hu",
    path: "/hu/szerzo-muvei/:slug()",
    meta: _91slug_93xlqKE8tV0IMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-by-author-slug___en",
    path: "/books-by-author/:slug()",
    meta: _91slug_93xlqKE8tV0IMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-category-subcategory___hu",
    path: "/hu/konyvek/:category()/:subcategory()",
    meta: indexwJSzQlQEpAMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "books-category-subcategory___en",
    path: "/books/:category()/:subcategory()",
    meta: indexwJSzQlQEpAMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "books-category___hu",
    path: "/hu/konyvek/:category()",
    meta: indexqYwgIXXFjZMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "books-category___en",
    path: "/books/:category()",
    meta: indexqYwgIXXFjZMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "books___hu",
    path: "/hu/konyvek",
    meta: indexXCRVd88LcNMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/books/index.vue")
  },
  {
    name: "books___en",
    path: "/books",
    meta: indexXCRVd88LcNMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/books/index.vue")
  },
  {
    name: "checkout___hu",
    path: "/hu/megrendeles",
    meta: indexyv0kPvPNYWMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    meta: indexyv0kPvPNYWMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "checkout-thankyou___hu",
    path: "/hu/megrendeles-koszonjuk",
    meta: thankyouVavZgIeagmMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "checkout-thankyou___en",
    path: "/checkout-thankyou",
    meta: thankyouVavZgIeagmMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "contact-us___hu",
    path: "/hu/kapcsolat",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/contact-us.vue")
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/contact-us.vue")
  },
  {
    name: "cookie___hu",
    path: "/hu/suti",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/cookie.vue")
  },
  {
    name: "cookie___en",
    path: "/cookie",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/cookie.vue")
  },
  {
    name: "index___hu",
    path: "/hu",
    meta: indexTSf5ni5fYIMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexTSf5ni5fYIMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/index.vue")
  },
  {
    name: "login___hu",
    path: "/hu/bejelentkezes",
    meta: loginnrxJd6WyPXMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginnrxJd6WyPXMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/login.vue")
  },
  {
    name: "menu-navigation-slug___hu",
    path: "/hu/menu-navigacio/:slug()",
    meta: _91slug_93JF0ivdzjKLMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "menu-navigation-slug___en",
    path: "/menu-navigation/:slug()",
    meta: _91slug_93JF0ivdzjKLMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "my-cart___hu",
    path: "/hu/kosaram",
    meta: my_45cartutMbe2ggIcMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/my-cart.vue")
  },
  {
    name: "my-cart___en",
    path: "/my-cart",
    meta: my_45cartutMbe2ggIcMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/my-cart.vue")
  },
  {
    name: "my-orders-slug___hu",
    path: "/hu/rendeleseim/:slug()",
    meta: _91slug_93kLLMoWotygMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "my-orders-slug___en",
    path: "/my-orders/:slug()",
    meta: _91slug_93kLLMoWotygMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "my-orders___hu",
    path: "/hu/rendeleseim",
    meta: indexsXJWS9OUR9Meta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "my-orders___en",
    path: "/my-orders",
    meta: indexsXJWS9OUR9Meta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "newly-published___hu",
    path: "/hu/ujdonsagok",
    meta: newly_45published3Bkz0k3a2EMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/newly-published.vue")
  },
  {
    name: "newly-published___en",
    path: "/newly-published",
    meta: newly_45published3Bkz0k3a2EMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/newly-published.vue")
  },
  {
    name: "newspapers___hu",
    path: "/hu/ujsagok-es-magazinok",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/newspapers.vue")
  },
  {
    name: "newspapers___en",
    path: "/newspapers-and-magazines",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/newspapers.vue")
  },
  {
    name: "on-sale___hu",
    path: "/hu/akciok",
    meta: on_45saleZyWlZ2zV66Meta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/on-sale.vue")
  },
  {
    name: "on-sale___en",
    path: "/on-sale",
    meta: on_45saleZyWlZ2zV66Meta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/on-sale.vue")
  },
  {
    name: "other-language-slug___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyv/:slug()",
    meta: _91slug_93nCjec526QTMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-language-slug___en",
    path: "/other-language/:slug()",
    meta: _91slug_93nCjec526QTMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-languages-category-subcategory___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:categorysubcategory?",
    meta: indexuo8iPhqjbRMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "other-languages-category-subcategory___en",
    path: "/other-languages/:category()/:subcategory()",
    meta: indexuo8iPhqjbRMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "other-languages-category___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:category()",
    meta: index80CJ1ibi2UMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "other-languages-category___en",
    path: "/other-languages/:category()",
    meta: index80CJ1ibi2UMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "other-languages___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek",
    meta: indexHn33N1pXDsMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "other-languages___en",
    path: "/other-languages",
    meta: indexHn33N1pXDsMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "payment-result___hu",
    path: "/hu/barion-eredmeny",
    meta: payment_45resultyL6DE5YJScMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/payment-result.vue")
  },
  {
    name: "payment-result___en",
    path: "/barion-result",
    meta: payment_45resultyL6DE5YJScMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/payment-result.vue")
  },
  {
    name: "paypal-start___hu",
    path: "/hu/paypal-fizetes",
    meta: paypal_45startEo1FzmM0blMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "paypal-start___en",
    path: "/paypal-start",
    meta: paypal_45startEo1FzmM0blMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "privacy-policy___hu",
    path: "/hu/adatkezelesi-tajekoztato",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "products-category-subcategory___hu",
    path: "/hu/product/:category?/:subcategory?",
    meta: _91_91subcategory_93_93KWZANLsm5OMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "products-category-subcategory___en",
    path: "/products/:category?/:subcategory?",
    meta: _91_91subcategory_93_93KWZANLsm5OMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "purchase-and-shipping-conditions___hu",
    path: "/hu/vasarlasi-es-szallitasi-feltetelek",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "purchase-and-shipping-conditions___en",
    path: "/purchase-and-shipping-conditions",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "search___hu",
    path: "/hu/kereses",
    meta: searchJwTi70F8GzMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    meta: searchJwTi70F8GzMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/search.vue")
  },
  {
    name: "staff-recommendations___hu",
    path: "/hu/mi-ajanljuk",
    meta: staff_45recommendationsKhPeqgvjA4Meta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "staff-recommendations___en",
    path: "/staff-recommendations",
    meta: staff_45recommendationsKhPeqgvjA4Meta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "stationary-and-gift-slug___hu",
    path: "/hu/notesz-es-ajandektargy/:slug()",
    meta: _91slug_93xID3gtxDuvMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gift-slug___en",
    path: "/stationary-and-gift/:slug()",
    meta: _91slug_93xID3gtxDuvMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___hu",
    path: "/hu/noteszek-es-ajandektargyak/:categorysubcategory?",
    meta: indexvkbzGkNvijMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___en",
    path: "/stationary-and-gifts/:category()/:subcategory()",
    meta: indexvkbzGkNvijMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category___hu",
    path: "/hu/noteszek-es-ajandektargyak/:category()",
    meta: index09q5p1SU8IMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "stationary-and-gifts-category___en",
    path: "/stationary-and-gifts/:category()",
    meta: index09q5p1SU8IMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "stationary-and-gifts___hu",
    path: "/hu/noteszek-es-ajandektargyak",
    meta: indexygIyY15OqJMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "stationary-and-gifts___en",
    path: "/stationary-and-gifts",
    meta: indexygIyY15OqJMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "terms-and-conditions___hu",
    path: "/hu/altalanos-szerzodesi-feltetelek",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "upcoming-releases___hu",
    path: "/hu/varhato-megjelenesek",
    meta: upcoming_45releasesThQIBxlGMWMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/upcoming-releases.vue")
  },
  {
    name: "upcoming-releases___en",
    path: "/upcoming-releases",
    meta: upcoming_45releasesThQIBxlGMWMeta || {},
    component: () => import("/codebuild/output/src2980918246/src/storefront/pages/upcoming-releases.vue")
  }
]